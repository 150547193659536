<template>
  <div>
    <div class="form-content">
      <h1 class="text-center">
        <img
          :src="require('../../assets/img/android-chrome-192x192.png').default"
          alt=""
          class="w-50 d-md-none"
        />
        <span class="brand-name" style="font-size: larger">GEROCARE CRM</span>
      </h1>

      <form class="text-left pt-2" @submit.prevent="loginUser()">
        <div class="form">
          <div id="username-field" class="field-wrapper input">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-user"
            >
              <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
              <circle cx="12" cy="7" r="4"></circle>
            </svg>
            <input
              id="username"
              name="username"
              type="email"
              v-model="email"
              class="form-control"
              placeholder="Email"
            />
          </div>

          <div id="password-field" class="field-wrapper input mb-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-lock"
            >
              <rect x="3" y="11" width="18" height="11" rx="2" ry="2"></rect>
              <path d="M7 11V7a5 5 0 0 1 10 0v4"></path>
            </svg>
            <input
              id="password"
              name="password"
              type="password"
              v-model="password"
              class="form-control"
              placeholder="Password"
            />
          </div>

          <div class="d-sm-flex justify-content-between">
            <div class="field-wrapper toggle-pass">
              <p class="d-inline-block">Show Password</p>
              <label class="switch s-primary">
                <input type="checkbox" id="toggle-password" class="d-none" />
                <span class="slider round"></span>
              </label>
            </div>

            <div class="field-wrapper">
              <button
                type="submit"
                class="btn btn-primary"
                value=""
                :disabled="loading"
              >
                <b-spinner small v-if="loading"></b-spinner>
                <span v-else>Login</span>
              </button>
            </div>
          </div>

          <div class="field-wrapper">
            <router-link to="/reset" class="forgot-pass-link"
              >Forgot Password?</router-link
            >
          </div>
        </div>
      </form>

      <div class="text-center">
        <p class="mt-4 small">© 2021. Gerocare Solutions LTD.</p>
      </div>
    </div>
  </div>
</template>

<script>
import { http } from "../../utils/http";
import { endpoints } from "../../utils/endpoints";

export default {
  name: "Login",

  props: ["redirect"],

  data() {
    return {
      email: "",
      password: "",
      loading: false,
    };
  },

  mounted() {
    this.togglePassword();
  },

  methods: {
    togglePassword() {
      var togglePassword = document.getElementById("toggle-password");

      if (togglePassword) {
        togglePassword.addEventListener("click", function () {
          var x = document.getElementById("password");
          if (x.type === "password") {
            x.type = "text";
          } else {
            x.type = "password";
          }
        });
      }
    },

    loginUser() {
      if (this.email == "") {
        this.$toast.error("Email is required");
      } else if (this.password == "") {
        this.$toast.error("Password is required");
      } else {
        this.loading = true;
        http
          .post(endpoints.LOGIN, {
            email: this.email,
            password: this.password,
          })
          .then((response) => {
            this.loading = false;
            this.$store.dispatch("authenticate", response);

            response.user.role == "ganar"
              ? this.$router.push("/tasks")
              : this.$router.push({
                  path: this.redirect ? this.redirect : "/",
                });
          })
          .catch((error) => {
            this.loading = false;
            this.$toast.error(
              error.response?.data?.message ||
                error.response?.message ||
                error.response?.data
            );
          });
      }
    },
  },
};
</script>
